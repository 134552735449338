import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";
import About from "./pages/about.js";
import Blog from "./pages/blog.js";
import Contact from "./pages/contact.js";
import Home from "./pages/home.js";
import Health from "./pages/health.js";
import Notes from "./pages/notes.js";
import Pets from "./pages/pets.js";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<About />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/health" exact element={<Health />} />
          <Route path="/pets" exact element={<Pets />} />
          <Route path="/notes" exact element={<Notes />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
