import React from "react";
import Container from "react-bootstrap/Container";

function Contact() {
  return (
    <Container>
      <div className="contact">
        <h1>Contact</h1>
      </div>
    </Container>
  );
}

export default Contact;
