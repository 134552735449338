import React from "react";
import Navbar from "react-bootstrap/Navbar";
import * as Icon from "react-bootstrap-icons";
import "../App.css";

function footer() {
  return (
    <div className="footer">
      <Navbar bg="light" expand="lg" fixed="bottom">
        <div className="links">
          <a href="https://www.linkedin.com/in/ladransfield/" class="icons">
            <Icon.Linkedin color="black" />
          </a>
          <a href="https://www.instagram.com/ladransfield/" class="icons">
            <Icon.Instagram color="black" />
          </a>
        </div>
      </Navbar>
    </div>
  );
}

export default footer;
