import React from "react";
import Container from "react-bootstrap/Container";

function Health() {
  return (
    <Container>
      <div className="health">
        <h1>Health</h1>
      </div>
    </Container>
  );
}

export default Health;
