import React from "react";
import Container from "react-bootstrap/Container";

function Pets() {
  return (
    <Container>
      <div className="pets">
        <h1>Pets</h1>
      </div>
    </Container>
  );
}

export default Pets;
