import React from "react";
import * as Icon from "react-bootstrap-icons";
import "../App.css";

function StarRating({score}) {

  const stars = []

  for (let i=0; i<5; i++) {
    if (i<score) {
      stars.push(<Icon.StarFill />)
    } 
    else {
      stars.push(<Icon.Star />)
    }
  }

  return (

    <span className="starRating">
      {stars}
    </span>
    
  );
  };
  
  export default StarRating;