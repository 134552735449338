import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "../App.css";

function navbar() {
  return (
    <div className="navbar">
      <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Brand href="/">Laurengineer</Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">About</Nav.Link>
            <Nav.Link href="/blog">Blog</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/home">Home</Nav.Link>
            <Nav.Link href="/health">Health</Nav.Link>
            <Nav.Link href="/pets">Pets</Nav.Link>
            <Nav.Link href="/notes">Notes</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Button variant="primary" disabled>
            Log in
          </Button>{" "}
        </Navbar.Collapse> */}
      </Navbar>
    </div>
  );
}

export default navbar;
