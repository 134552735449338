import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Self from "../assets/self.jpeg";
import StarRating from "../components/StarRating.js";
import "../App.css";

function About() {
  return (
    <Container>
      <div className="about">
        <div className="header">
          <img src={Self} alt="Lauren's face" className="profile" />
          <h1>Hi, I'm Lauren</h1>
        </div>

        <Container fluid>
          <Row>
            <p>
              Chartered Engineer with over 10 years' experience in simulation
              and analysis, including computational fluid dynamics (CFD), 1D
              multiphysics simulations, and electromagnetic finite element
              analysis (FEA). Enthusiastic about the development of
              methodologies and tools to optimise both product and workflow
              effectiveness and efficiency.
            </p>
          </Row>
          <Row>
            <Col sm={{ order: "last", span: 8 }}>
              <h2>Professional experience</h2>
              <h4>Babcock International Group</h4>
              <h5>
                Principal Fluid Analysis Engineer
                <span style={{ float: "right" }}>Jul 2024 - present</span>
              </h5>
              <p>
                Responsible for ensuring that the team are using the the most appropriate methodologies and that our tools are suitable
              </p>
              <p>
                Supporting method development where required, and ensuring that as a team we drive our capabilities forward
              </p>
              <p>
                Responsible for the training and development of colleagues to
                use simulation and programming packages in which I am more
                highly skilled.
              </p>
              <p>
                Discipline Team Leader, supporting other Mechanical Engineers'
                training and development needs.
              </p>
              <p>
                Graduate Mentor, ensuring that the mentee is fulfilling their
                professional development needs and meeting their own personal
                goals throughout the graduate scheme.
              </p>
              <h5>
                Senior Fluid Analysis Engineer
                <span style={{ float: "right" }}>Jun 2018 - Jul 2024</span>
              </h5>
              <p>
                Generated budget estimates and detailed timing and modelling
                plans for simulation projects, to which the whole team are held
                responsible when conducting tasks.
              </p>
              <p>
                Programmed custom components for use within 1D models of
                hydraulic systems in AMESim, in the C programming language.
              </p>
              <p>
                Developed new high fidelity 1D models in AMESim to replicate and
                predict the response of complex hydraulic systems.
              </p>
              <p>
                Predicted the response of an automated pallet handling system to
                ensure customer requirements could be met when manufactured and
                installed.
              </p>
              <h4>Delphi Technologies</h4>
              <h5>
                Fluids and Systems Analysis Engineer
                <span style={{ float: "right" }}>Oct 2015 - Jun 2018</span>
              </h5>
              <p>
                Predicted expected first time pass rates of future products
                prior to manufacture with randomised six-sigma tolerance
                distributions, using statistical analyses in MATLAB using 1D
                modelling in AMESim.
              </p>
              <p>
                Established transient electromagnetic FEA methodologies using
                ANSYS Maxwell and set business-wide modelling best practices,
                with delivery of training to colleagues covering both the
                underlying physics and the simulation tools.
              </p>
              <p>
                Conducted regular internal audits to IATF-16949, requiring an
                in-depth knowledge of the business quality systems and
                procedures across a range of business functions.
              </p>
              <h5>
                Graduate Fluids and Systems Analysis Engineer
                <span style={{ float: "right" }}>Oct 2012 - Oct 2015</span>
              </h5>
              <p>
                Designed and developed empirical models using AMESim for
                sub-second simulations of complex products with minimal error
                when compared to high fidelity multi-physics simulations.
              </p>
              <p>
                Employed a combination of 1D modelling with AMESim, CFD with
                ANSYS Fluent, and electromagnetic FEA with ANSYS Maxwell, to
                optimise and improve both the performance and robustness of
                future products.
              </p>
              <p>
                Used MATLAB and Python to develop tools which streamline
                workflows for myself and my colleagues, reducing time spent on
                simple predictable tasks.
              </p>
              <h2>Awards</h2>
              <p>Winner of Babcock International Group's 2021 hackathon</p>
              <h2>Publications</h2>
              <p>
                A paper titled "Heavy duty fuel systems - developing the next
                generation", written by myself and three colleagues to be
                published in and presented at "
                <a
                  href="https://events.imeche.org/docs/default-source/combustion-engines-and-fuels-events/c1409-fuel-systems-brochure_z_web_update_3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fuel Systems for IC Engines: Inject Your Ideas, Fuel Your
                  Technology
                </a>
                "
              </p>
              <p>
                A patent titled "Fuel Injector", publication number "
                <a
                  href="https://worldwide.espacenet.com/patent/search/family/061131749/publication/GB2569627A?q=pn%3DGB2569627A"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GB2569627A
                </a>
                ", describing a novel plate valve for asymmetric flow control of
                a fuel injector
              </p>
              <h2>Personal life</h2>
              <p>
              I enjoy learning new skills in my home life such as programming. Sometimes creating web-apps for personal projects, or programming microcontrollers such as Raspberry Pis and Arduinos.
              </p>
              <p>
              I have designed and 3D printed several small components on my own 3D printer, recently using this to create my own desktop CNC mill.
              </p>
              <p>
              I also enjoy exercise and spend a lot of time walking with my dog, playing rugby with a local team at the weekend, and powerlifting with a nearby club. During the summer I also play in a fun rounders league.
              </p>
            </Col>
            <Col sm={{ order: "first", span: 4 }}>
              <h2>Skills</h2>
              <h5>Computational fluid dynamics</h5>
              <p>
                ANSYS Fluent <StarRating score={4} />
              </p>
              <p>
                ANSYS CFX <StarRating score={4} />
              </p>
              <p>
                OpenFOAM <StarRating score={2} />
              </p>
              <h5>Finite element analysis</h5>
              <p>
                ANSYS Mechanical <StarRating score={2} />
              </p>
              <h5>1D modelling</h5>
              <p>
                Simcenter AMESim <StarRating score={4} />
              </p>
              <p>
                Simcenter FloMaster <StarRating score={3} />
              </p>
              <h5>Electromagnetics</h5>
              <p>
                ANSYS Maxwell <StarRating score={4} />
              </p>
              <h5>Multibody dynamics</h5>
              <p>
                MSC Adams <StarRating score={2} />
              </p>
              <p>
                ANSYS Motion <StarRating score={2} />
              </p>
              <h5>Process emulation</h5>
              <p>
                Emulate 3D <StarRating score={3} />
              </p>
              <h5>Computer aided design</h5>
              <p>
                SolidWorks <StarRating score={3} />
              </p>
              <p>
                OnShape <StarRating score={3} />
              </p>
              <p>
                NX <StarRating score={2} />
              </p>
              <h5>Programming</h5>
              <p>
                Matlab <StarRating score={4} />
              </p>
              <p>
                Python <StarRating score={3} />
              </p>
              <p>
                HTML / CSS / JavaScript <StarRating score={3} />
              </p>
              <p>
                C / C++ <StarRating score={2} />
              </p>
              <h2>Training</h2>



              <h5>Managing Safely</h5>
              <p>
                IOSH
                <span style={{ float: "right" }}>May 2024</span>
              </p>
              <h5>Introduction to Practical CFD</h5>
              <p>
                NAFEMS
                <span style={{ float: "right" }}>Feb 2024</span>
              </p>
              <h5>Lifecycle Management and Systems Engineering</h5>
              <p>
                Burge Hughes Walsh
                <span style={{ float: "right" }}>Mar 2023</span>
              </p>
              <h5>Adult Mental Health First Aid (Two Day)</h5>
              <p>
                MHFA England
                <span style={{ float: "right" }}>Nov 2021</span>
              </p>
              <h5>Introduction to Simcenter AMESim</h5>
              <p>
                Siemens
                <span style={{ float: "right" }}>May 2019</span>
              </p>
              <h5>Working Safely</h5>
              <p>
                IOSH
                <span style={{ float: "right" }}>Jan 2019</span>
              </p>
              <h5>Introduction to Machining</h5>
              <p>
                AMRC
                <span style={{ float: "right" }}>Nov 2016</span>
              </p>
              <h5>Internal Auditor (IATF-16949)</h5>
              <p>
                Lloyds Register
                <span style={{ float: "right" }}>Nov 2015</span>
              </p>
              <h5>Introduction to Computational Fluid Dynamics</h5>
              <p>
                NAFEMS
                <span style={{ float: "right" }}>Mar 2013</span>
              </p>
              <h5>Introduction to MATLAB</h5>
              <p>
                MathWorks
                <span style={{ float: "right" }}>Dec 2012</span>
              </p>









            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
}

export default About;
