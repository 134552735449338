import React from "react";
import Container from "react-bootstrap/Container";

function Home() {
  return (
    <Container>
      <div className="home">
        <h1>Home</h1>
      </div>
    </Container>
  );
}

export default Home;
